


import { pinyin } from "pinyin-pro";
import { ManpowerMenu } from "@/consts/ArcCodes"
import { post, index } from "@/consts/AiCodes";
let conversion = function (str, that) {
  str = pinyin(str, { toneType: 'none' });
  if (!str.includes("xiao ai")) {
    return
  }
  let aaa = false;
  let path = null;
  let postindex = index


  //判断是否

  let excludeList = [
    "自主化决策",
    "推荐式决策",
    "审批式决策",
    "流程挖掘",
    "电子合同",
    "自助服务",
    "Ai大模型",
    "决策引擎",
    "神经网络",
    "内容挖掘",
    "生成式Ai",
    "智能识别",
    "语言处理",
    "语言识别",
    "机器学习",
    "数据大模型",
    "Ai测评",
    "BI数据库",
    "数据湖",
    "数据岛",
    "数据舱",
    "数据可视化",
    "决策层报表",
    "实时监控"
  ]



  for (let index = 0; index < excludeList.length; index++) {
    const item = excludeList[index];
    if (str.includes(pinyin(item.replace(/[^\u4e00-\u9fa5]/gi, ""), { toneType: 'none' }))) {
      that.$speech.text = "请申请开通此权限" // 获取并设置说话时的文本
      that.$speech.rate = 1;
      speechSynthesis.speak(that.$speech);
      aaa = true
      break;
    }
  }

  if (aaa) {
    return
  }

  for (let index = 0; index < post.length; index++) {
    const item = post[index];
    if (str.includes(pinyin(item.name.replace(/[^\u4e00-\u9fa5]/gi, ""), { toneType: 'none' }))) {
      that.$speech.text = "正在帮您打开" + item.name.replace(/[^\u4e00-\u9fa5]/gi, "") // 获取并设置说话时的文本
      that.$speech.rate = 1;
      speechSynthesis.speak(that.$speech);

      path = item.url
      aaa = true
      break;
    }
  }

  if (aaa) {
    that.$router.push({
      name: "office",
      params: {
        url: path
      }
    });
  }

  
  for (let index = 0; index < postindex.length; index++) {
    const item = postindex[index];
    if (str.includes(pinyin(item.name.replace(/[^\u4e00-\u9fa5]/gi, ""), { toneType: 'none' }))) {

      that.$speech.text = "正在帮您打开" + item.name.replace(/[^\u4e00-\u9fa5]/gi, "") // 获取并设置说话时的文本
      that.$speech.rate = 1;
      speechSynthesis.speak(that.$speech);
      path = item.url
      aaa = true
      break;
    }
  }


  for (let index = 0; index < postindex.length; index++) {
    const item = postindex[index];
    if (str.includes(pinyin(item.name.replace(/[^\u4e00-\u9fa5]/gi, ""), { toneType: 'none' }))) {

      that.$speech.text = "正在帮您打开" + item.name.replace(/[^\u4e00-\u9fa5]/gi, "") // 获取并设置说话时的文本
      that.$speech.rate = 1;
      speechSynthesis.speak(that.$speech);
      path = item.url
      aaa = true
      break;
    }
  }
  if (aaa) {
    that.$router.push({
      name: "office",
      params: {
        url: path
      }
    });
  }
  let quickEntryData = ManpowerMenu;
  for (let index = 0; index < quickEntryData.length; index++) {
    const item = quickEntryData[index];
    try {
      if (str.includes(pinyin(item.title.replace(/[^\u4e00-\u9fa5]/gi, ""), { toneType: 'none' }))) {

        that.$speech.text = "正在帮您打开" + item.title.replace(/[^\u4e00-\u9fa5]/gi, "") // 获取并设置说话时的文本
        that.$speech.rate = 1;
        speechSynthesis.speak(that.$speech);
        aaa = true
        break;
      }

    } catch (e) {
      that.$router.push(item.path);
    }

  }
  if (aaa) {
    return
  }

  let arr = [
    {
      "id": "20",
      "name": null,
      "path": "/Recruitment/recruitingPlan",
      "authName": "招聘计划",
      "pid": "0",
      "isMenu": true,
      "url": null,
      "imgSrc": null,
      "createTime": null,
      "updateTime": null,
      "children": null
    },
    {
      "id": "21",
      "name": null,
      "path": "/Recruitment/RecruitmentApplication",
      "authName": "招聘申请",
      "pid": "0",
      "isMenu": true,
      "url": null,
      "imgSrc": null,
      "createTime": null,
      "updateTime": null,
      "children": null
    },
    {
      "id": "22",
      "name": null,
      "path": "/Recruitment/positionManagement",
      "authName": "级别管理",
      "pid": "0",
      "isMenu": true,
      "url": null,
      "imgSrc": null,
      "createTime": null,
      "updateTime": null,
      "children": null
    },
    {
      "id": "24",
      "name": null,
      "path": "/Recruitment/evaluationManagement",
      "authName": "评价管理",
      "pid": "0",
      "isMenu": true,
      "url": null,
      "imgSrc": null,
      "createTime": null,
      "updateTime": null,
      "children": null
    },
    {
      "id": "25",
      "name": null,
      "path": "/Recruitment/Index",
      "isIndex":"1",
      "authName": "招聘驾驶舱",
      "pid": "1",
      "isMenu": true,
      "url": null,
      "imgSrc": null,
      "createTime": null,
      "updateTime": null,
      "children": null
    },
    {
      "id": "26",
      "name": null,
      "path": "/Recruitment/TalentPool",
      "authName": "人才储备库",
      "pid": "0",
      "isMenu": true,
      "url": null,
      "imgSrc": null,
      "createTime": null,
      "updateTime": null,
      "children": null
    },
    {
      "id": "27",
      "name": null,
      "path": "/Recruitment/install",
      "authName": "招聘设置",
      "pid": "0",
      "isMenu": true,
      "url": null,
      "imgSrc": null,
      "createTime": null,
      "updateTime": null,
      "children": null
    },
    {
      "id": "29",
      "name": null,
      "path": "/Train/TrainingNeeds",
      "authName": "培训需求",
      "pid": "2",
      "isMenu": false,
      "url": null,
      "imgSrc": null,
      "createTime": null,
      "updateTime": null,
      "children": null
    },
    {
      "id": "30",
      "name": null,
      "path": "/Train/trainingPlan",
      "authName": "培训计划",
      "pid": "2",
      "isMenu": false,
      "url": null,
      "imgSrc": null,
      "createTime": null,
      "updateTime": null,
      "children": null
    },
    {
      "id": "31",
      "name": null,
      "path": "/Train/TrainingApplication",
      "authName": "培训申请",
      "pid": "2",
      "isMenu": false,
      "url": null,
      "imgSrc": null,
      "createTime": null,
      "updateTime": null,
      "children": null
    },
    {
      "id": "32",
      "name": null,
      "path": "/Train/TrainingTestingAndExamination",
      "authName": "训测练考",
      "pid": "2",
      "isMenu": false,
      "url": null,
      "imgSrc": null,
      "createTime": null,
      "updateTime": null,
      "children": null
    },
    {
      "id": "33",
      "name": null,
      "path": "/Train/TrainingManagement",
      "authName": "培训管理",
      "pid": "2",
      "isMenu": false,
      "url": null,
      "imgSrc": null,
      "createTime": null,
      "updateTime": null,
      "children": null
    },
    {
      "id": "34",
      "name": null,
      "path": "/Train/Index",
      "authName": "培训驾驶舱",
      "isIndex":"1",
      "pid": "2",
      "isMenu": true,
      "url": null,
      "imgSrc": null,
      "createTime": null,
      "updateTime": null,
      "children": null
    },
    {
      "id": "35",
      "name": null,
      "path": "/Train/EfficiencyManagement",
      "authName": "培训课程",
      "pid": "2",
      "isMenu": false,
      "url": null,
      "imgSrc": null,
      "createTime": null,
      "updateTime": null,
      "children": null
    },
    {
      "id": "36",
      "name": null,
      "path": "/Train/TrainingTestingAndExamination?data=5",
      "authName": "AI监考",
      "pid": "2",
      "isMenu": true,
      "url": null,
      "imgSrc": null,
      "createTime": null,
      "updateTime": null,
      "children": null
    },
    {
      "id": "38",
      "name": null,
      "path": "/Performance/Index",
      "authName": "绩效驾驶舱",
      "isIndex":"1",
      "pid": "3",
      "isMenu": true,
      "url": null,
      "imgSrc": null,
      "createTime": null,
      "updateTime": null,
      "children": null
    },
    {
      "id": "39",
      "name": null,
      "path": "/Performance/taskCoordination",
      "authName": "任务协同",
      "pid": "3",
      "isMenu": false,
      "url": null,
      "imgSrc": null,
      "createTime": null,
      "updateTime": null,
      "children": null
    },
    {
      "id": "40",
      "name": null,
      "path": "/Performance/dayWeekMonth",
      "authName": "日清周清月清",
      "pid": "3",
      "isMenu": false,
      "url": null,
      "imgSrc": null,
      "createTime": null,
      "updateTime": null,
      "children": null
    },
    {
      "id": "41",
      "name": null,
      "path": "/Performance/projectManagement",
      "authName": "参加项目",
      "pid": "3",
      "isMenu": false,
      "url": null,
      "imgSrc": null,
      "createTime": null,
      "updateTime": null,
      "children": null
    },
    {
      "id": "42",
      "name": null,
      "path": "/Performance/strategicDecoding",
      "authName": "战略解码",
      "pid": "3",
      "isMenu": true,
      "url": null,
      "imgSrc": null,
      "createTime": null,
      "updateTime": null,
      "children": null
    },
    {
      "id": "43",
      "name": null,
      "path": "/Performance/ObjectiveDecomposition",
      "authName": "目标分解",
      "pid": "3",
      "isMenu": true,
      "url": null,
      "imgSrc": null,
      "createTime": null,
      "updateTime": null,
      "children": null
    },
    {
      "id": "44",
      "name": null,
      "path": "/Performance/TaskDecomposition",
      "authName": "任务分解 ",
      "pid": "3",
      "isMenu": true,
      "url": null,
      "imgSrc": null,
      "createTime": null,
      "updateTime": null,
      "children": null
    },
    {
      "id": "45",
      "name": null,
      "path": "/Performance/NewProjectManagement",
      "authName": "项目管理",
      "pid": "3",
      "isMenu": true,
      "url": null,
      "imgSrc": null,
      "createTime": null,
      "updateTime": null,
      "children": null
    },
    {
      "id": "46",
      "name": null,
      "path": "/Performance/IndicatorLibrary",
      "authName": "指标档案库",
      "pid": "3",
      "isMenu": false,
      "url": null,
      "imgSrc": null,
      "createTime": null,
      "updateTime": null,
      "children": null
    },
    {
      "id": "47",
      "name": null,
      "path": "/Performance/InitiateAssessment",
      "authName": "发起考核",
      "pid": "3",
      "isMenu": false,
      "url": null,
      "imgSrc": null,
      "createTime": null,
      "updateTime": null,
      "children": null
    },
    {
      "id": "48",
      "name": null,
      "path": "/Performance/checkEvaluate",
      "authName": "考核评价",
      "pid": "3",
      "isMenu": false,
      "url": null,
      "imgSrc": null,
      "createTime": null,
      "updateTime": null,
      "children": null
    },
    {
      "id": "49",
      "name": null,
      "path": "/Performance/Assessment",
      "authName": "绩效设置",
      "pid": "3",
      "isMenu": false,
      "url": null,
      "imgSrc": null,
      "createTime": null,
      "updateTime": null,
      "children": null
    },
    {
      "id": "50",
      "name": null,
      "path": "/Performance/PerformanceScreen",
      "authName": "绩效大屏",
      "pid": "3",
      "isMenu": true,
      "url": null,
      "imgSrc": null,
      "createTime": null,
      "updateTime": null,
      "children": null
    },
    {
      "id": "52",
      "name": null,
      "path": null,
      "authName": "业务",
      "pid": "7",
      "isMenu": false,
      "url": null,
      "imgSrc": null,
      "createTime": null,
      "updateTime": null,
      "children": null
    },
    {
      "id": "53",
      "name": null,
      "path": "/Recruitment/WisdomPrimary?data=1",
      "authName": "职位匹配",
      "pid": "1",
      "isMenu": true,
      "url": null,
      "imgSrc": null,
      "createTime": null,
      "updateTime": null,
      "children": null
    },
    {
      "id": "54",
      "name": null,
      "path": "/Salary/index",
      "authName": "薪酬驾驶舱",
      "isIndex":"1",
      "pid": "4",
      "isMenu": true,
      "url": null,
      "imgSrc": null,
      "createTime": null,
      "updateTime": null,
      "children": null
    },
    {
      "id": "55",
      "name": "Page/TotalAmountControl",
      "path": "/Salary/Page/TotalAmountControl",
      "authName": "总额控制",
      "pid": "4",
      "isMenu": true,
      "url": null,
      "imgSrc": null,
      "createTime": null,
      "updateTime": null,
      "children": null
    },
    {
      "id": "56",
      "name": "Page/SalaryManagement",
      "path": "/Salary/Page/SalaryManagement",
      "authName": "定薪管理",
      "pid": "4",
      "isMenu": true,
      "url": null,
      "imgSrc": null,
      "createTime": null,
      "updateTime": null,
      "children": null
    },
    {
      "id": "57",
      "name": "Page/SalaryAdjustment",
      "path": "/Salary/Page/SalaryAdjustment",
      "authName": "调薪管理",
      "pid": "4",
      "isMenu": true,
      "url": null,
      "imgSrc": null,
      "createTime": null,
      "updateTime": null,
      "children": null
    },
    {
      "id": "58",
      "name": "Page/SalaryAccounting",
      "path": "/Salary/Page/SalaryAccounting",
      "authName": "薪酬核算",
      "pid": "4",
      "isMenu": true,
      "url": null,
      "imgSrc": null,
      "createTime": null,
      "updateTime": null,
      "children": null
    },
    {
      "id": "59",
      "name": null,
      "path": "/Salary/SalaryScreen",
      "authName": "薪酬大屏",
      "pid": "4",
      "isMenu": true,
      "url": null,
      "imgSrc": null,
      "createTime": null,
      "updateTime": null,
      "children": null
    },
    {
      "id": "60",
      "name": null,
      "path": "/Workbench/Index",
      "authName": "快捷",
      "pid": "5",
      "isMenu": true,
      "url": null,
      "imgSrc": null,
      "createTime": null,
      "updateTime": null,
      "children": null
    },
    {
      "id": "61",
      "name": null,
      "path": "/ProcessCenter/ApplyFor",
      "authName": "申请",
      "pid": "5",
      "isMenu": true,
      "url": null,
      "imgSrc": null,
      "createTime": null,
      "updateTime": null,
      "children": null
    },
    {
      "id": "62",
      "name": null,
      "path": "/ProcessCenter/News",
      "authName": "通知",
      "pid": "5",
      "isMenu": true,
      "url": null,
      "imgSrc": null,
      "createTime": null,
      "updateTime": null,
      "children": null
    },
    {
      "id": "63",
      "name": null,
      "path": "/ProcessCenter/News",
      "authName": "会议",
      "pid": "5",
      "isMenu": true,
      "url": null,
      "imgSrc": null,
      "createTime": null,
      "updateTime": null,
      "children": null
    },
    {
      "id": "64",
      "name": null,
      "path": null,
      "authName": "公文",
      "pid": "5",
      "isMenu": true,
      "url": null,
      "imgSrc": null,
      "createTime": null,
      "updateTime": null,
      "children": null
    },
    {
      "id": "65",
      "name": null,
      "path": "CommandCenter/HumanResources",
      "authName": "人力报表",
      "pid": "5",
      "isMenu": false,
      "url": null,
      "imgSrc": null,
      "createTime": null,
      "updateTime": null,
      "children": null
    },
    {
      "id": "66",
      "name": null,
      "path": null,
      "authName": "审批",
      "pid": "6",
      "isMenu": true,
      "url": null,
      "imgSrc": null,
      "createTime": null,
      "updateTime": null,
      "children": null
    },
    {
      "id": "67",
      "name": null,
      "path": null,
      "authName": "业务",
      "pid": "6",
      "isMenu": true,
      "url": null,
      "imgSrc": null,
      "createTime": null,
      "updateTime": null,
      "children": null
    },
    {
      "id": "68",
      "name": null,
      "path": "",
      "authName": "数据大模型",
      "pid": "7",
      "isMenu": true,
      "url": null,
      "imgSrc": null,
      "createTime": null,
      "updateTime": null,
      "children": null
    },
    {
      "id": "69",
      "name": null,
      "path": null,
      "authName": "Ai测评",
      "pid": "7",
      "isMenu": true,
      "url": "http://cp.airencai.com.cn/customer",
      "imgSrc": null,
      "createTime": null,
      "updateTime": null,
      "children": null
    },
    {
      "id": "70",
      "name": null,
      "path": null,
      "authName": "BI数据库",
      "pid": "7",
      "isMenu": true,
      "url": null,
      "imgSrc": null,
      "createTime": null,
      "updateTime": null,
      "children": null
    },
    {
      "id": "71",
      "name": null,
      "path": null,
      "authName": "数据湖",
      "pid": "7",
      "isMenu": true,
      "url": null,
      "imgSrc": null,
      "createTime": null,
      "updateTime": null,
      "children": null
    },
    {
      "id": "72",
      "name": null,
      "path": null,
      "authName": "数据岛",
      "pid": "7",
      "isMenu": true,
      "url": null,
      "imgSrc": null,
      "createTime": null,
      "updateTime": null,
      "children": null
    },
    {
      "id": "73",
      "name": null,
      "path": null,
      "authName": "数据舱",
      "pid": "7",
      "isMenu": true,
      "url": null,
      "imgSrc": null,
      "createTime": null,
      "updateTime": null,
      "children": null
    },
    {
      "id": "74",
      "name": null,
      "path": null,
      "authName": "数据可视化",
      "pid": "7",
      "isMenu": true,
      "url": null,
      "imgSrc": null,
      "createTime": null,
      "updateTime": null,
      "children": null
    },
    {
      "id": "75",
      "name": null,
      "path": "/CommandCenter/DecisionMaking",
      "authName": "决策层报表",
      "pid": "7",
      "isMenu": true,
      "url": null,
      "imgSrc": null,
      "createTime": null,
      "updateTime": null,
      "children": null
    },
    {
      "id": "76",
      "name": null,
      "path": null,
      "authName": "实时监控",
      "pid": "7",
      "isMenu": true,
      "url": null,
      "imgSrc": null,
      "createTime": null,
      "updateTime": null,
      "children": null
    },
    {
      "id": "77",
      "name": null,
      "path": "/Recruitment/WisdomPrimary?data=3",
      "authName": "能力测试",
      "pid": "1",
      "isMenu": true,
      "url": null,
      "imgSrc": null,
      "createTime": null,
      "updateTime": null,
      "children": null
    },
    {
      "id": "78",
      "name": null,
      "path": "/Recruitment/evaluationManagement?data=12",
      "authName": "人才决策",
      "pid": "1",
      "isMenu": true,
      "url": null,
      "imgSrc": null,
      "createTime": null,
      "updateTime": null,
      "children": null
    },
    {
      "id": "79",
      "name": null,
      "path": "/tissueTree/roster",
      "authName": "花名册",
      "pid": "10",
      "isMenu": true,
      "url": null,
      "imgSrc": null,
      "createTime": null,
      "updateTime": null,
      "children": null
    },
    {
      "id": "80",
      "name": null,
      "path": "/tissueTree/Company",
      "authName": "组织架构",
      "pid": "10",
      "isMenu": true,
      "url": null,
      "imgSrc": null,
      "createTime": null,
      "updateTime": null,
      "children": null
    },
    {
      "id": "81",
      "name": null,
      "path": "/tissueTree/Finalization",
      "authName": "编制管理",
      "pid": "10",
      "isMenu": true,
      "url": null,
      "imgSrc": null,
      "createTime": null,
      "updateTime": null,
      "children": null
    },
    {
      "id": "82",
      "name": null,
      "path": "/tissueTree/perCapitaEfficiency",
      "authName": "人均效能",
      "pid": "10",
      "isMenu": true,
      "url": null,
      "imgSrc": null,
      "createTime": null,
      "updateTime": null,
      "children": null
    },
    {
      "id": "83",
      "name": null,
      "path": "/tissueTree/ListB",
      "authName": "B名单",
      "pid": "10",
      "isMenu": true,
      "url": null,
      "imgSrc": null,
      "createTime": null,
      "updateTime": null,
      "children": null
    },
    {
      "id": "84",
      "name": null,
      "path": "/tissueTree/User",
      "authName": "个人中心",
      "pid": "10",
      "isMenu": true,
      "url": null,
      "imgSrc": null,
      "createTime": null,
      "updateTime": null,
      "children": null
    },
    {
      "id": "112",
      "name": null,
      "path": "/tissueTree/workFlow",
      "authName": "工作流",
      "pid": "10",
      "isMenu": true,
      "url": null,
      "imgSrc": null,
      "createTime": null,
      "updateTime": null,
      "children": null
    },
    {
      "id": "85",
      "name": null,
      "path": "/Setup/Brandset",
      "authName": "品牌logo",
      "pid": "9",
      "isMenu": true,
      "url": null,
      "imgSrc": null,
      "createTime": null,
      "updateTime": null,
      "children": null
    },
    {
      "id": "86",
      "name": null,
      "path": "/Setup/users",
      "authName": "角色管理",
      "pid": "9",
      "isMenu": true,
      "url": null,
      "imgSrc": null,
      "createTime": null,
      "updateTime": null,
      "children": null
    },
    {
      "id": "87",
      "name": null,
      "path": "/Setup/roles",
      "authName": "权限管理",
      "pid": "9",
      "isMenu": true,
      "url": null,
      "imgSrc": null,
      "createTime": null,
      "updateTime": null,
      "children": null
    },
    {
      "id": "88",
      "name": null,
      "path": "/Setup/module",
      "authName": "模块设置",
      "pid": "9",
      "isMenu": true,
      "url": null,
      "imgSrc": null,
      "createTime": null,
      "updateTime": null,
      "children": null
    },
    {
      "id": "89",
      "name": null,
      "path": "/Setup/PositionManagement",
      "authName": "级别管理",
      "pid": "9",
      "isMenu": true,
      "url": null,
      "imgSrc": null,
      "createTime": null,
      "updateTime": null,
      "children": null
    },
    {
      "id": "90",
      "name": null,
      "path": null,
      "authName": "面试小助理",
      "pid": "8",
      "isMenu": true,
      "url": null,
      "imgSrc": null,
      "createTime": null,
      "updateTime": null,
      "children": null
    },
    {
      "id": "91",
      "name": null,
      "path": null,
      "authName": "培训小助理",
      "pid": "8",
      "isMenu": true,
      "url": null,
      "imgSrc": null,
      "createTime": null,
      "updateTime": null,
      "children": null
    },
    {
      "id": "92",
      "name": null,
      "path": null,
      "authName": "文本小助理",
      "pid": "8",
      "isMenu": true,
      "url": null,
      "imgSrc": null,
      "createTime": null,
      "updateTime": null,
      "children": null
    },
    {
      "id": "93",
      "name": null,
      "path": null,
      "authName": "指标小助理",
      "pid": "8",
      "isMenu": true,
      "url": null,
      "imgSrc": null,
      "createTime": null,
      "updateTime": null,
      "children": null
    },
    {
      "id": "94",
      "name": null,
      "path": null,
      "authName": "薪酬小助理",
      "pid": "8",
      "isMenu": true,
      "url": null,
      "imgSrc": null,
      "createTime": null,
      "updateTime": null,
      "children": null
    },
    {
      "id": "95",
      "name": null,
      "path": null,
      "authName": "说明书小助理",
      "pid": "8",
      "isMenu": true,
      "url": null,
      "imgSrc": null,
      "createTime": null,
      "updateTime": null,
      "children": null
    },
    {
      "id": "96",
      "name": null,
      "path": null,
      "authName": "画像小助理",
      "pid": "8",
      "isMenu": true,
      "url": null,
      "imgSrc": null,
      "createTime": null,
      "updateTime": null,
      "children": null
    },
    {
      "id": "97",
      "name": null,
      "path": null,
      "authName": "图谱小助理",
      "pid": "8",
      "isMenu": true,
      "url": null,
      "imgSrc": null,
      "createTime": null,
      "updateTime": null,
      "children": null
    },
    {
      "id": "98",
      "name": null,
      "path": null,
      "authName": "制度小助理",
      "pid": "8",
      "isMenu": true,
      "url": null,
      "imgSrc": null,
      "createTime": null,
      "updateTime": null,
      "children": null
    },
    {
      "id": "99",
      "name": null,
      "path": "/CommandCenter/DecisionMaking",
      "authName": "决策层报表",
      "pid": "11",
      "isMenu": false,
      "url": null,
      "imgSrc": null,
      "createTime": null,
      "updateTime": null,
      "children": null
    },
    {
      "id": "100",
      "name": null,
      "path": "/Recruitment/RecruitmentReport",
      "authName": "招聘数字报表",
      "authName": "招聘报表",
      "pid": "11",
      "isMenu": false,
      "url": null,
      "imgSrc": null,
      "createTime": null,
      "updateTime": null,
      "children": null
    },
    {
      "id": "101",
      "name": null,
      "path": "/Train/Report",
      "authName": "培训数字报表",
      "pid": "11",
      "isMenu": false,
      "url": null,
      "imgSrc": null,
      "createTime": null,
      "updateTime": null,
      "children": null
    },
    {
      "id": "102",
      "name": null,
      "path": "/Performance/ReportDownload",
      "authName": "绩效数字报表",
      "pid": "11",
      "isMenu": false,
      "url": null,
      "imgSrc": null,
      "createTime": null,
      "updateTime": null,
      "children": null
    },
    {
      "id": "103",
      "name": null,
      "path": "/Salary/ReportDownload",
      "authName": "薪酬数字报表",
      "pid": "11",
      "isMenu": false,
      "url": null,
      "imgSrc": null,
      "createTime": null,
      "updateTime": null,
      "children": null
    },
    {
      "id": "104",
      "name": null,
      "path": "/CommandCenter/HumanResources",
      "authName": "人力报表",
      "pid": "11",
      "isMenu": false,
      "url": null,
      "imgSrc": null,
      "createTime": null,
      "updateTime": null,
      "children": null
    },
    {
      "id": "105",
      "name": null,
      "path": "/Recruitment/RecruitmentScreen",
      "authName": "招聘大屏",
      "pid": "1",
      "isMenu": true,
      "url": null,
      "imgSrc": null,
      "createTime": null,
      "updateTime": null,
      "children": null
    },
    {
      "id": "108",
      "name": null,
      "path": "/Train/TrainingNeeds",
      "authName": "精准需求",
      "pid": "2",
      "isMenu": true,
      "url": null,
      "imgSrc": null,
      "createTime": null,
      "updateTime": null,
      "children": null
    },
    {
      "id": "109",
      "name": null,
      "path": "/Train/AiSparring",
      "authName": "AI陪练",
      "pid": "2",
      "isMenu": true,
      "url": null,
      "imgSrc": null,
      "createTime": null,
      "updateTime": null,
      "children": null
    },
    {
      "id": "110",
      "name": null,
      "path": null,
      "authName": "AI阅卷",
      "pid": "2",
      "isMenu": true,
      "url": null,
      "imgSrc": null,
      "createTime": null,
      "updateTime": null,
      "children": null
    },
    {
      "id": "111",
      "name": null,
      "path": "/Train/TrainingScreen",
      "authName": "培训大屏",
      "pid": "2",
      "isMenu": true,
      "url": null,
      "imgSrc": null,
      "createTime": null,
      "updateTime": null,
      "children": null
    },
    {
      "id": "112",
      "name": null,
      "path": "/Performance/AAAnew",
      "authName": "绩效数据",
      "pid": "3",
      "isMenu": true,
      "url": null,
      "imgSrc": null,
      "createTime": null,
      "updateTime": null,
      "children": null
    },
    {
      "id": "113",
      "name": null,
      "path": "/Performance/effectiveness",
      "authName": "组织效能",
      "pid": "3",
      "isMenu": true,
      "url": null,
      "imgSrc": null,
      "createTime": null,
      "updateTime": null,
      "children": null
    },
  ]

  for (let inde = 0; inde < arr.length; inde++) {
    const element = arr[inde];
    if (str.includes(pinyin(element.authName, { toneType: 'none' }))) {

      that.$speech.text = "正在帮您打开" + element.authName // 获取并设置说话时的文本
      that.$speech.rate = 1;
      speechSynthesis.speak(that.$speech);
      that.$router.push(element.path);
      aaa = true;
      break;
    }
    if (aaa) {
      break;
    }
  }
  if (aaa) {
    return
  }

  // let { submeterList } = that.$store.state;
  // for (let index = 0; index < submeterList.length; index++) {
  //   const item = submeterList[index];
  //   if (str.includes(pinyin(item.tilte.replace(/[^\u4e00-\u9fa5]/gi, ""), { toneType: 'none' }))) {
  //     
  //     setTimeout(function () {
  //       that.$recognition.start();
  //     }, 2000);
  //     that.$speech.text = "正在帮您打开" + item.tilte.replace(/[^\u4e00-\u9fa5]/gi, "") // 获取并设置说话时的文本
  //     speechSynthesis.speak(that.$speech);
  //     that.$router.push({
  //       name: "Roster/luckySheet",
  //       params: {
  //         data: {
  //           luckyData: item.data,
  //           title: item.title,
  //           type: "submeter"
  //         }
  //       }
  //     })
  //     break;
  //   }
  // }

  // that.$speech.text = "抱歉 未找到" //
  // speechSynthesis.speak(that.$speech);

  if (aaa) {
    return
  }
}
export default conversion;

