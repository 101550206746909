export let post = [
  {
    name: "成本会计岗位说明书",
    url: "https://airencai.oss-cn-beijing.aliyuncs.com/%E5%B2%97%E4%BD%8D/%E5%B2%97%E4%BD%8D%E8%AF%B4%E6%98%8E%E4%B9%A6/%E6%88%90%E6%9C%AC%E4%BC%9A%E8%AE%A1%E5%B2%97%E4%BD%8D%E8%AF%B4%E6%98%8E%E4%B9%A6.docx",
  },
  {
    name: "人力发展主管岗位说明书",
    url: "https://airencai.oss-cn-beijing.aliyuncs.com/%E5%B2%97%E4%BD%8D/%E5%B2%97%E4%BD%8D%E8%AF%B4%E6%98%8E%E4%B9%A6/%E4%BA%BA%E5%8A%9B%E5%8F%91%E5%B1%95%E4%B8%BB%E7%AE%A1%E5%B2%97%E4%BD%8D%E8%AF%B4%E6%98%8E%E4%B9%A6.docx",
  },
  {
    name: "人力资源部副部长岗位说明书",
    url: "https://airencai.oss-cn-beijing.aliyuncs.com/%E5%B2%97%E4%BD%8D/%E5%B2%97%E4%BD%8D%E8%AF%B4%E6%98%8E%E4%B9%A6/%E4%BA%BA%E5%8A%9B%E8%B5%84%E6%BA%90%E9%83%A8%E5%89%AF%E9%83%A8%E9%95%BF%E5%B2%97%E4%BD%8D%E8%AF%B4%E6%98%8E%E4%B9%A6.docx",
  },
  {
    name: "人力资源部部长岗位说明书",
    url: "https://airencai.oss-cn-beijing.aliyuncs.com/%E5%B2%97%E4%BD%8D/%E5%B2%97%E4%BD%8D%E8%AF%B4%E6%98%8E%E4%B9%A6/%E4%BA%BA%E5%8A%9B%E8%B5%84%E6%BA%90%E9%83%A8%E9%83%A8%E9%95%BF%E5%B2%97%E4%BD%8D%E8%AF%B4%E6%98%8E%E4%B9%A6.docx",
  },
  {
    name: "应付会计岗位说明书",
    url: "https://airencai.oss-cn-beijing.aliyuncs.com/%E5%B2%97%E4%BD%8D/%E5%B2%97%E4%BD%8D%E8%AF%B4%E6%98%8E%E4%B9%A6/%E5%BA%94%E4%BB%98%E4%BC%9A%E8%AE%A1%E5%B2%97%E4%BD%8D%E8%AF%B4%E6%98%8E%E4%B9%A6.docx",
  },
  {
    name: "总经理秘书岗位说明书",
    url: "https://airencai.oss-cn-beijing.aliyuncs.com/%E5%B2%97%E4%BD%8D/%E5%B2%97%E4%BD%8D%E8%AF%B4%E6%98%8E%E4%B9%A6/%E6%80%BB%E7%BB%8F%E7%90%86%E7%A7%98%E4%B9%A6%E5%B2%97%E4%BD%8D%E8%AF%B4%E6%98%8E%E4%B9%A6.docx",
  },
  {
    name: "成本会计岗位说明书",
    url: "https://airencai.oss-cn-beijing.aliyuncs.com/%E5%B2%97%E4%BD%8D/%E5%B2%97%E4%BD%8D%E8%AF%B4%E6%98%8E%E4%B9%A6/%E6%88%90%E6%9C%AC%E4%BC%9A%E8%AE%A1%E5%B2%97%E4%BD%8D%E8%AF%B4%E6%98%8E%E4%B9%A6.docx",
  },
  {
    name: "综合管理专员岗位说明书",
    url: "https://airencai.oss-cn-beijing.aliyuncs.com/%E5%B2%97%E4%BD%8D/%E5%B2%97%E4%BD%8D%E8%AF%B4%E6%98%8E%E4%B9%A6/%E7%BB%BC%E5%90%88%E7%AE%A1%E7%90%86%E4%B8%93%E5%91%98%E5%B2%97%E4%BD%8D%E8%AF%B4%E6%98%8E%E4%B9%A6.docx",
  },
  {
    name: "行政主管岗位说明书",
    url: "https://airencai.oss-cn-beijing.aliyuncs.com/%E5%B2%97%E4%BD%8D/%E5%B2%97%E4%BD%8D%E8%AF%B4%E6%98%8E%E4%B9%A6/%E8%A1%8C%E6%94%BF%E4%B8%BB%E7%AE%A1%E5%B2%97%E4%BD%8D%E8%AF%B4%E6%98%8E%E4%B9%A6.docx",
  },
];
export let index = [
  {
    name: "人力专员绩效考核模板",
    url: "https://airencai.oss-cn-beijing.aliyuncs.com/%E6%8C%87%E6%A0%87/%E4%BA%BA%E5%8A%9B%E4%B8%93%E5%91%98%E7%BB%A9%E6%95%88%E8%80%83%E6%A0%B8%E6%A8%A1%E6%9D%BF.docx",
  },
  {
    name: "前端开发工程师绩效考核模板",
    url: "https://airencai.oss-cn-beijing.aliyuncs.com/%E6%8C%87%E6%A0%87/%E5%89%8D%E7%AB%AF%E5%BC%80%E5%8F%91%E5%B7%A5%E7%A8%8B%E5%B8%88%E7%BB%A9%E6%95%88%E8%80%83%E6%A0%B8%E6%A8%A1%E6%9D%BF.docx",
  },
  {
    name: "办公室主任绩效考核模板",
    url: "https://airencai.oss-cn-beijing.aliyuncs.com/%E6%8C%87%E6%A0%87/%E5%8A%9E%E5%85%AC%E5%AE%A4%E4%B8%BB%E4%BB%BB%E7%BB%A9%E6%95%88%E8%80%83%E6%A0%B8%E6%A8%A1%E6%9D%BF.docx",
  },
  {
    name: "后端工程师绩效考核模板",
    url: "https://airencai.oss-cn-beijing.aliyuncs.com/%E6%8C%87%E6%A0%87/%E5%90%8E%E7%AB%AF%E5%B7%A5%E7%A8%8B%E5%B8%88%E7%BB%A9%E6%95%88%E8%80%83%E6%A0%B8%E6%A8%A1%E6%9D%BF.docx",
  },
  {
    name: "商务预算工程师绩效考核模板",
    url: "https://airencai.oss-cn-beijing.aliyuncs.com/%E6%8C%87%E6%A0%87/%E5%95%86%E5%8A%A1%E9%A2%84%E7%AE%97%E5%B7%A5%E7%A8%8B%E5%B8%88%E7%BB%A9%E6%95%88%E8%80%83%E6%A0%B8%E6%A8%A1%E6%9D%BF.docx",
  },
  {
    name: "技术工程师绩效考核模板",
    url: "https://airencai.oss-cn-beijing.aliyuncs.com/%E6%8C%87%E6%A0%87/%E6%8A%80%E6%9C%AF%E5%B7%A5%E7%A8%8B%E5%B8%88%E7%BB%A9%E6%95%88%E8%80%83%E6%A0%B8%E6%A8%A1%E6%9D%BF.docx",
  },
  {
    name: "新媒体运营管理工程师绩效考核模板",
    url: "https://airencai.oss-cn-beijing.aliyuncs.com/%E6%8C%87%E6%A0%87/%E6%96%B0%E5%AA%92%E4%BD%93%E8%BF%90%E8%90%A5%E7%AE%A1%E7%90%86%E5%B7%A5%E7%A8%8B%E5%B8%88%E7%BB%A9%E6%95%88%E8%80%83%E6%A0%B8%E6%A8%A1%E6%9D%BF.docx",
  },
  {
    name: "测量管理工程师绩效考核模板",
    url: "https://airencai.oss-cn-beijing.aliyuncs.com/%E6%8C%87%E6%A0%87/%E6%B5%8B%E9%87%8F%E7%AE%A1%E7%90%86%E5%B7%A5%E7%A8%8B%E5%B8%88%E7%BB%A9%E6%95%88%E8%80%83%E6%A0%B8%E6%A8%A1%E6%9D%BF.docx",
  },
];
export let gtpData =[];

export let roster = {
  jobNumber: "工号",
  name: "姓名",
  gender: "性别",
  age: "年龄",
  company: "集团或公司",
  department: "部门",
  post: "岗位",
  team : "组别",
  jobSeries: "岗位系列",
  position: "职位",
  siLing: "司龄",
  level: "层级",
  education: "学历",
  title: "职称",
  professionalOne: "专业1",
  professionalTwo: "专业2",
  professionalThree: "专业3",
  graduationInstitution: "毕业院校",
  graduationYear: "毕业年度",
  qualificationOrCertificate: "资质或证书",
  maritalStatus: "婚姻状况",
  nation: "民族",
  politicalOutlook: "政治面貌",
  idNo: "身份证号码",
  phone: "联系电话",
  weChat: "微信",
  email: "邮箱",
  personnelStatus: "人员状态",
  permanentResidence: "户口所在地",
  nativePlace: "籍贯",
  homeAddress: "家庭住址",
  birthday: "生日",
  joinedDate: "入职日期",
  probationDate: "转正日期",
  terminationDate: "离职日期",
  affiliatedUnit: "所属单位",
  unitSigningLaborContract: "签订劳动合同单位",
  theNumberOfTimesTheContractWasSigned: "第几次签订合同",
  contractTerm: "合同年限",

  lastContractSigningDate: "最近一次合同签订日期",
  contractExpirationDate: "合同到期日期",
  employmentForm: "用工形式",
  workLocation: "工作地点",
  emergencyContact: "紧急联系人",
  relationship: "关系",
  emergencyContactInformation: "紧急联系方式",
  paymentOfSocialSecurity: "是否符合缴纳社保条件",
  whetherToPaySocialSecurity: "是否缴纳社保",
  paymentConditionsForHousingProvidentFund: "是否符合缴纳公积金条件",
  accumulationFund: "是否缴纳公积金",
  occupationalHealth: "职业健康",
  physicalExaminationDate : "体检时间",
  riskAssessmentForBecomingARegularEmployee: "转正风险评估",
  expirationDateOfIdCard: "身份证到期日期",
};

// 简历
export let RecruitmentData = {
  name: "姓名",
  sex: "性别",
  age: "年龄",
  education: "学历",
  certificates: "证书",
  expectedIndustry: "期望行业",
  expectedLocation: "期望地点",
  expectedPosition: "期望职位",
  languageSkills: "语言技巧",
  mailbox: "邮箱",
  maritalStatus: "婚姻状态",
  nativePlace: "籍贯",
  phoneNumber: "手机号",
  politicalOutlook: "政治面貌",
  salaryExpectation: "期望薪资",
  skillsObjs: "技能",
  speciality: "专业",
  position: "职位",
  workExperience: "工作经验",
  workingCondition: "工作状态",
  workLocation: "工作地点",
  wages: "工资",
  expectedDateOfArrival: "预计到达日期",
  createTime: "创建时间",
  title: "职称",
  recruitingNumbers: "招聘人数",
  numberOfRecruitedPersonnel: "已招人数",
  jobDescription: "工作描述",
};

// 绩效
export let PerformanceData = {
  nickName: "姓名",
  department: "部门",
  post: "岗位",
  endOfSelfAssessmentTime: "自评结束日期",
  assessmentEndTtime: "考核结束日期",
  notes: "备注",
  date: "日期",
  quarter: "季度",
  title: "考核名称",
  createTime: "创建时间",
  selfAssessment: "自评",
  keyTaskName: "名称",
  descriptionOfKeyTaskContent: "内容描述",
  requiredCompletionTime: "完成时间",
  completionStatus: "完成情况",
  maturityCompletionRate: "完成率",
  actualFinish: "完成时间",
  targetValue: "目标值",
  state: "状态",
  finishOnTime: "按时完成",
  userName: "姓名",
  type: "类型",
  notes: "备注",
  company: "公司",
  operatingRevenue: "营业收入 (万元)",
  netProfit: "净利润 (万元)",
  totalCost: "成本费用总额(万元)",
  totalLaborCost: "人工成本总额 (万元)",
  perCapitaOperatingIncome: "人均营业收入 (万元)",
  perCapitaNetProfit: "人均净利润 (万元)",
  perCapitaLaborCost: "人均人力成本(万元)",
  personnelCostRate: "人事费用率(%)",
  theProportionOfTotalLaborCostsToTotalCostExpenses:
    "人力成本总额占成本费用总额的比重(%)",
  laborCostProfitMargin: "人力成本利润率(%)",
  lastYearTheFirstHalfOfOurCompany: "去年本公司上半年",
  lastYearTheSecondHalfOfOurCompany: "今年本公司上半年",
  yoy: "同比",
  performanceScore: "得分",
  completionRate: "绩效完成率",
  score: "自评分数"
};

// 培训
export let Training = {
  trainingTheme: "课程名称",
  createTime: "创建时间",
  credit: "学分",
  estimate: "评估",
  integral: "积分",
  place: "培训地点",
  plannedNumberOfPeople: "计划人数",
  qrCode: "签到二维码",
  signIn: "签到",
  state: "状态",
  trainees: "培训对象",
  trainingCategory: "培训类别",
  trainingCosts: "培训费用",
  trainingDate: "培训日期",
  trainingFormat: "培训形式",
  trainingHours: "培训时数",
  trainingInstructor: "培训讲师",
  trainingMethods: "培训方式",
  trainingType: "培训类型",
  tratingQrCode: "评聘二维码",
  reason: "个人自荐理由",
  userName: "姓名",
  nickName: "姓名",
  department: "部门",
  post: "岗位",
  lecturerLevel: "讲师级别",
  speciality: "特长描述",
  course: "主讲课程",
  teaching: "授课经历",
  experience: "培训经历",
  title : '标题'
};

// 培训
export let Salary = {
  trainingTheme: "课程名称",
  createTime: "创建时间",
  credit: "学分",
  estimate: "评估",
  integral: "积分",
  place: "培训地点",
  plannedNumberOfPeople: "计划人数",
  qrCode: "签到二维码",
  signIn: "签到",
  state: "状态",
  trainees: "培训对象",
  trainingCategory: "培训类别",
  trainingCosts: "培训费用",
  trainingDate: "培训日期",
  trainingFormat: "培训形式",
  trainingHours: "培训时数",
  trainingInstructor: "培训讲师",
  trainingMethods: "培训方式",
  trainingType: "培训类型",
  tratingQrCode: "评聘二维码",
  reason: "个人自荐理由",
  userName: "姓名",
  nickName: "姓名",
  department: "部门",
  post: "岗位",
  lecturerLevel: "讲师级别",
  speciality: "特长描述",
  course: "主讲课程",
  teaching: "授课经历",
  experience: "培训经历",
};

export let basicHumanResourcesData = {
  date: "日期",
  underTwentyYearsOld: "20岁以下",
  twentyTwentyNineToYearsOld: "20-29岁",
  thirtyThirtyNineToYearsOld: "30-39岁",
  fortyFortyNineToYearsOld: "40-49岁",
  fiftyFiftyFourToYearsOld: "50-54岁",
  fiftyFiveYearsOldAndAbove: "55岁(含)以上",
  oneThreeMonths: "1-3个月",
  fourMonthsToOneYear: "4个月-1年",
  oneYearToTwoYear: "1年-2年",
  oneYearToTwoYear: "3年-4年",
  fiveYearToTenYear: "5年-10年",
  elevenYearToFifteenYear: "11年-15年",
  overSixteenYears: "16年(含)以上",
  doctor: "博士",
  masterDegreeOrAbove: "硕士及以上",
  undergraduateCourse: "本科",
  juniorCollege: "大专",
  highSchool: "高中/中专",
  belowHighSchool: "初中及以下",
  man: "男",
  woman: "女",
}


// 人力驾驶舱
export let WorkbenchData = {
  name: "姓名",
  department: "部门",
  age: "年龄",
  post: "岗位",
  level: "层级",
  birthday: "生日",
  company: "集团或公司",
  education: "学历",
  emergencyContactInformation: "紧急联系方式",
  employmentForm: "用工形式",
  graduationInstitution: "毕业院校",
  graduationYear: "毕业年度",
  idNo: "身份证号码",
  jobNumber: "工号",
  gender: "性别",
  homeAddress: "家庭住址",
  permanentResidence: "户口所在地",
  jobSeries: "岗位系列",
  maritalStatus: "婚姻状况",
  nativePlace: "籍贯",
  nation: "民族",
  paymentConditionsForHousingProvidentFund: "是否符合缴纳公积金条件",
  paymentOfSocialSecurity: "是否符合缴纳社保条件",
  personnelStatus: "人员状态",
  phone: "联系电话",
  politicalOutlook: "政治面貌",
  position: "职位",
  probationDate: "转正日期",
  professionalOne: "专业1",
  professionalTwo: "专业2",
  professionalThree: "专业3",
  qualificationOrCertificate: "资质或证书",
  relationship: "关系",
  riskAssessmentForBecomingARegularEmployee: "转正风险评估",
  theNumberOfTimesTheContractWasSigned: "第几次签订合同",
  unitSigningLaborContract: "签订劳动合同单位",
  weChat: "微信",
  whetherToPaySocialSecurity: "是否缴纳社保",
  workLocation: "工作地点",
  contractExpirationDate: "合同到期日期",
  affiliatedUnit: "所属单位",
  accumulationFund: "是否缴纳公积金",

};

