import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
// require("@/orgutils/CustomUtil")
import Element from "element-ui";
import "../theme/index.css";
import "@/assets/css/actEm.less";
import { logout } from "@/airencaiapi/user";
import { pako_zip, pako_unzip } from "@/utils/pako";
import pdfDownload from "@/utils/pdfDownload";
import downExcel from "./utils/excel/downExcel";
import excelToJson from "@/utils/excelToJson";
import "@/components/ef/index.css";
import "@/utils/flexlab";
import '@/assets/orgcss/theme.less'
//导入图标样式
import "@/assets/orgcss/iconfont/iconfont.css"
import Ellipsis from '@/components/OrkformDesin/Ellipsis'
import WDialog from '@/components/OrkformDesin/WDialog'
import Tip from '@/components/OrkformDesin/Tip'
import vueEsign from 'vue-esign'
import directivedrag from "./directive/directivedrag";
import { EventBus } from './utils/eventBus'
// import formCreate from "@form-create/element-ui";
// import FcDesigner from '@form-create/designer';
Vue.use(Element);
Vue.use(Ellipsis);
Vue.use(WDialog);
Vue.use(Tip);
Vue.use(vueEsign)
Vue.use(directivedrag);
Vue.prototype.$eventBus = EventBus;
// Vue.use(FcDesigner)
// el-dialog点击遮罩层不关闭弹窗
Element.Dialog.props.closeOnClickModal.default = false;
// try{
//   Vue.prototype.$speech = new SpeechSynthesisUtterance();
// }catch(e){
// 
//   console.error("不存在SpeechSynthesisUtterance,",e);
  
// }
Vue.prototype.$speech = {};
// try{
  
// Vue.prototype.$recognition = new webkitSpeechRecognition();
// }catch(e){
//   console.error("不存在SpeechSynthesisUtterance,",e);
  
// }

Vue.prototype.$recognition = {};


Vue.config.performance = true;
Vue.prototype.$Logout = async function () {
  let res = await logout();
  if (res.code == 200) {
    Element.Message.success(res.msg);
    localStorage.removeItem("arcUser");
    localStorage.removeItem("token");
    localStorage.removeItem("arcUserType");
    localStorage.removeItem("saixuanData");
    localStorage.removeItem("approveList");
    router.push("/login");
  }
};

Vue.prototype.$pdfDownload = function (that, ref) {
  pdfDownload(that, ref);
};

Vue.prototype.$pako_zip = function (str) {
  return pako_zip(str);
};
Vue.prototype.$isNotEmpty = function(obj){
  return (obj !== undefined && obj !== null && obj !== '' && obj !== 'null')
}

Vue.prototype.$getDefalut = function(obj, key, df){
  return (obj === undefined || key === undefined || !this.$isNotEmpty(obj[key])) ? df : obj[key];
}

Vue.prototype.$deepCopy = function (obj){return JSON.parse(JSON.stringify(obj))}

Vue.prototype.$verifyRermissions = function (purviewName, permissionValue) {
  if (a[purviewName] != undefined) {
    if (!a[purviewName].includes(permissionValue)) {
      this.$message.error("您暂无此权限");
      return true;
    }
  } else {
    this.$message.error("您暂无此权限");
    return true;
  }
};

import VueTypedJs from "vue-typed-js";
Vue.use(VueTypedJs);
Vue.prototype.$pako_unzip = function (str, e = false) {
  return pako_unzip(str, e);
};

Vue.prototype.$res = function (res) {
  if (res.code == 200) {
    Element.Message.success(res.msg);
  } else {
    Element.Message.error(res.msg);
  }
};

Vue.prototype.$excelToJson = function (file, da) {
  return excelToJson(file, da);
};

Vue.prototype.$downExcel = function (excelData, tHeader, filterVal, excelName) {
  downExcel(excelData, tHeader, filterVal, excelName);
};

// 导入字体图标
import "./assets/fonticon/iconfont.css";

import dataV from "@jiaminghi/data-view";
Vue.use(dataV);

// 导航组件
import AllNavigationPage from "@/views/AllNavigationPage";
Vue.component("Navigation", AllNavigationPage);

Vue.config.productionTip = false;
import scroll from "vue-seamless-scroll";
Vue.use(scroll);

//按钮权限指令
Vue.directive('auto',{
  bind(el, binding) {},
  inserted(el,binding){
    //获取本地存储
    let data = localStorage.getItem('arcUserType');
    console.log('指令data',data);
    // 4以上为hr
    if(data<4){
      el.parentNode.removeChild(el);
    }
  }
})

// 数字过度指令
Vue.directive('digitalTransition',{
  inserted(el, binding) {
    // 数据是请求过来的
    let circulation = setInterval(() => {

      if (el.innerText  !== '0' ) {
        clearInterval(circulation);
        circulation = null;
        digital(el); // 数字过渡
      }
    },100)
    
    function digital(el) {

       // 最终要显示的数字
      let finalNum = el.innerText;
      // 动画间隔
      let rate = 50;
      // 总时长
      let time = 1000;
      // 步长,数据增加间隔
      let step = finalNum / (time / rate);
      // 初始值
      let count = 0;
      // 要保留的小数
      let timer = setInterval(() => {
        count = count + step;
        el.innerText = count.toFixed(0);
        if (count > finalNum) {
          //  避免count大于finalNum最终数字显示不对
          count = finalNum;
          el.innerText = count;
          // 清空定时器
          clearInterval(timer);
          timer = null;
        }
      }, rate);
    }
  },
})




new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
